import '../styles/globals.css';
import Head from "next/head";
import {useRouter} from "next/router";
import {Fragment, useEffect} from "react";
import {setCookie} from "../helpers/cookies";
import useScrollTo from "react-spring-scroll-to-hook";
import {config} from "@react-spring/web";
import {Analytics} from '@vercel/analytics/react';


const baseUrl = 'https://nextmove.cz';

function setUtmCookie(urlParams) {
    const utmSource = urlParams.get('utm_source');
    const utmMedium = urlParams.get('utm_medium');
    const utmCampaign = urlParams.get('utm_campaign');

    if (utmSource || utmMedium || utmCampaign) {
        const utmData = {
            utm_source: utmSource,
            utm_medium: utmMedium,
            utm_campaign: utmCampaign,
        };

        const utmDataString = JSON.stringify(utmData);
        setCookie('utmData', utmDataString, 10);
    }
}

function MyApp({Component, pageProps}) {
    const {locales, locale, defaultLocale, asPath} = useRouter();
    const ogUrl = (locale === 'cs' ? `${baseUrl}${asPath}` : `${baseUrl}${locale}${asPath}`).split('?')[0];

    const {scrollTo} = useScrollTo(config.default);

    useEffect(() => {
        if (window) {
            const urlParams = new URLSearchParams(window.location.search);
            setUtmCookie(urlParams);
        }
    }, []);

    useEffect(() => {
        if (window && window.location.hash) {
            const hashQuery = window.location.hash.split('?')[0];
            const element = document.querySelector(hashQuery);

            if (element) {
                setTimeout(() => {
                    scrollTo(hashQuery, -100);
                }, 500);
            }
        }
    }, [scrollTo]);

    return (
        <>
            <Head>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
                <link rel="manifest" href="/site.webmanifest"/>
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000000"/>
                <meta name="msapplication-TileColor" content="#ffffff"/>
                <meta name="theme-color" content="#ffffff"/>

                <link rel="preload" href="/fonts/Garet-Regular.otf" as="font" type="font/otf" crossOrigin="true"/>
                <link rel="preload" href="/fonts/Garet-Medium.otf" as="font" type="font/otf" crossOrigin="true"/>
                <link rel="preload" href="/fonts/Garet-Book.otf" as="font" type="font/otf" crossOrigin="true"/>

                <meta name="facebook-domain-verification" content="6onjpugnzp2z993qqjmjfufcelh0xs"/>

                <meta property="og:type" content="place"/>
                <meta property="og:url" content={ogUrl}/>

                <link rel="canonical" href={ogUrl}/>

                {locales.map((lang) => {
                    let path = asPath === '/' ? '' : asPath;
                    path = path.split('?')[0].split('#')[0];
                    const langPath = lang === 'cs' ? '' : `/${lang}`;
                    const url = `${baseUrl}${langPath}${path}`;

                    return (
                        <Fragment key={`LinkAlternate: ${lang}`}>
                            {lang === defaultLocale && (
                                <link rel="alternate" href={url} hrefLang="x-default"/>
                            )}
                            <link rel="alternate" href={url} hrefLang={lang}/>
                        </Fragment>
                    )
                })}
            </Head>

            <Component {...pageProps} />
            <Analytics/>
        </>
    )
}

export default MyApp
